// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-crux-js": () => import("./../src/pages/projects/crux.js" /* webpackChunkName: "component---src-pages-projects-crux-js" */),
  "component---src-pages-projects-designs-js": () => import("./../src/pages/projects/designs.js" /* webpackChunkName: "component---src-pages-projects-designs-js" */),
  "component---src-pages-projects-events-js": () => import("./../src/pages/projects/events.js" /* webpackChunkName: "component---src-pages-projects-events-js" */),
  "component---src-pages-projects-js": () => import("./../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-projects-matteblack-js": () => import("./../src/pages/projects/matteblack.js" /* webpackChunkName: "component---src-pages-projects-matteblack-js" */),
  "component---src-pages-projects-portraits-js": () => import("./../src/pages/projects/portraits.js" /* webpackChunkName: "component---src-pages-projects-portraits-js" */),
  "component---src-pages-projects-posterfolio-js": () => import("./../src/pages/projects/posterfolio.js" /* webpackChunkName: "component---src-pages-projects-posterfolio-js" */),
  "component---src-pages-projects-unilag-js": () => import("./../src/pages/projects/unilag.js" /* webpackChunkName: "component---src-pages-projects-unilag-js" */),
  "component---src-pages-projects-wecare-js": () => import("./../src/pages/projects/wecare.js" /* webpackChunkName: "component---src-pages-projects-wecare-js" */),
  "component---src-pages-success-js": () => import("./../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

